import React, {
  useCallback, useEffect, useState,
} from 'react';
import {
  Card,
  CircularProgress,
  Collapse,
  Grid,
  Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import {
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { BsPlusSquareFill } from 'react-icons/bs';
import {
  BusinessApplication,
  BusinessLocation,
  generateBusinessApplicationLocation,
  updateBusinessApplication,
} from '../../../Services/BusinessApplicationService';
import {
  CardTitle,
  CardTitleText,
  ExpandedContent,
  ExpandMore,
  StyledButton,
  StyledIconButton,
} from './shared';
import { SupportedCountry } from '../../../Services/BillingService';
import BusinessApplicationLocationsCard from './BusinessApplicationLocationsCard';
import { BusinessCategory } from '../../../Services/BusinessService';

type FieldArrayLocation = BusinessLocation & { id: string };

function BusinessApplicationLocationsDetails({
  businessApplication,
  isDataLoading,
  errorOnDataLoading,
  supportedCountries,
  businessCategories,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication;
  isDataLoading: boolean,
  errorOnDataLoading: boolean,
  supportedCountries: SupportedCountry[],
  businessCategories: BusinessCategory[],
  onSetBusinessApplicationToEditHandler: (
    businessApplication: BusinessApplication
  ) => void;
}>) {
  const formMethods = useForm<BusinessApplication>()!;
  const { fields, replace, append } = useFieldArray({
    control: formMethods.control,
    name: 'locations' as unknown as never,
  });
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (businessApplication.locations.length > 0) {
      replace(businessApplication.locations);
    }
  }, [businessApplication.updatedAt]);

  const onAddNewLocationHandler = async () => {
    try {
      const newLocation = generateBusinessApplicationLocation(
        businessApplication,
        fields.length + 1,
      );
      const result = await updateBusinessApplication(
        { ...businessApplication, locations: [...businessApplication.locations, newLocation] },
      );
      onSetBusinessApplicationToEditHandler(result.data);
      append(newLocation);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onRemoveLocationHandler = useCallback(async (locationIndex: number) => {
    try {
      setError(false);
      setIsLoading(true);
      const updatedFields: FieldArrayLocation[] = [];

      (fields as unknown as FieldArrayLocation[]).forEach((location, index) => {
        const currentLocationNumber = parseInt(
          location.locationId.split('-')[1],
          10,
        );

        if (index < locationIndex) {
          updatedFields.push(location);
          return;
        }

        if (index !== locationIndex && index > locationIndex) {
          updatedFields.push({
            ...location,
            locationId: `${location.businessId}-${(
              currentLocationNumber - 1
            )
              .toString()
              .padStart(3, '0')}`,
          });
        }
      });
      const result = await updateBusinessApplication(
        { ...businessApplication, locations: updatedFields },
      );
      onSetBusinessApplicationToEditHandler(result.data);
      replace(updatedFields);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  }, [fields.length]);

  const onFormSubmitHandler = async (data: { locations: BusinessLocation[] }) => {
    try {
      setError(false);
      setIsLoading(true);
      const result = await updateBusinessApplication(
        { ...businessApplication, locations: data.locations },
      );
      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardTitle>
        <CardTitleText variant="h4">Locations details</CardTitleText>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <StyledIconButton
            disabled={!isExpanded}
            onClick={() => onAddNewLocationHandler()}
            size="small"
          >
            <BsPlusSquareFill />
          </StyledIconButton>
          <ExpandMore
            expand={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          {fields.length === 0 && (
            <Typography variant="h6" textAlign="center">
              No locations to edit
            </Typography>
          )}
          {fields.length !== 0 && (
            <form onSubmit={formMethods.handleSubmit(onFormSubmitHandler)}>
              <FormProvider {...formMethods}>
                {fields.map((locationData, locationIndex) => (
                  <BusinessApplicationLocationsCard
                    key={locationData.id}
                    locationData={locationData as unknown as BusinessLocation}
                    locationIndex={locationIndex}
                    locationsCount={fields.length}
                    isDataLoading={isDataLoading}
                    errorOnDataLoading={errorOnDataLoading}
                    supportedCountries={supportedCountries}
                    businessCategories={businessCategories}
                    isLoading={isLoading}
                    onRemoveLocationHandler={onRemoveLocationHandler}
                  />
                ))}
              </FormProvider>
              <Grid item xs={12} sm={12} sx={{ marginTop: '20px' }}>
                <StyledButton
                  disabled={isLoading}
                  fullWidth
                  type="submit"
                  withChildComponent={isLoading}
                >
                  {isLoading ? (
                    <CircularProgress size="20px" />
                  ) : (
                    'Save locations'
                  )}
                </StyledButton>
                {formMethods.formState.errors?.locations && (
                  <Typography
                    variant="body2"
                    color="error"
                    textAlign="center"
                    marginTop="15px"
                  >
                    One of more locations are missing required fields
                  </Typography>
                )}
                {error && (
                  <Typography
                    variant="body2"
                    color="error"
                    textAlign="center"
                    marginTop="15px"
                  >
                    Something went wrong while submitting the update, please try
                    again or contact support
                  </Typography>
                )}
              </Grid>
            </form>
          )}
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessApplicationLocationsDetails;
