import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Box,
  Card,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { AiFillDelete } from 'react-icons/ai';
import { MdExpandMore } from 'react-icons/md';
import {
  CardTitle,
  CardTitleText,
  ExpandedContent,
  ExpandMore,
  FieldErrorMessage,
  StyledIconButton,
} from './shared';
import { SupportedCountry } from '../../../Services/BillingService';
import { BusinessLocation } from '../../../Services/BusinessApplicationService';
import {
  openingHoursFormatErrorMessage,
  openingHoursPatternValidation,
  requiredFieldErrorMessage,
} from '../utils';
import GoogleMapContainer from '../../../Components/BasicComponents/GoogleMapContainer';
import { BusinessCategory } from '../../../Services/BusinessService';

function BusinessApplicationLocationsCard({
  locationData,
  locationIndex,
  locationsCount,
  isDataLoading,
  errorOnDataLoading,
  supportedCountries,
  businessCategories,
  isLoading,
  onRemoveLocationHandler,
}: Readonly<{
  locationData: BusinessLocation;
  locationIndex: number;
  locationsCount: number;
  isDataLoading: boolean;
  errorOnDataLoading: boolean;
  supportedCountries: SupportedCountry[];
  businessCategories: BusinessCategory[];
  isLoading: boolean;
  onRemoveLocationHandler: (locationIndex: number) => void;
}>) {
  const { register, formState } = useFormContext<{
    locations: BusinessLocation[];
  }>();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card
      sx={{
        width: '100%',
        '&:not(:first-of-type)': {
          marginTop: '15px',
        },
      }}
    >
      <CardTitle>
        <CardTitleText variant="h4">{locationData.locationId}</CardTitleText>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <StyledIconButton
            size="small"
            disabled={locationsCount === 1 || isLoading}
            onClick={() => onRemoveLocationHandler(locationIndex)}
          >
            <AiFillDelete />
          </StyledIconButton>
          <ExpandMore
            expand={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container gap={3} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h4">General details</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.contact`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                defaultValue={locationData.contact}
                error={!!formState.errors.locations?.[locationIndex]?.contact}
                label="Contact"
              />
              {formState.errors.locations?.[locationIndex]?.contact && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.contact
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.email`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                defaultValue={locationData.email}
                error={!!formState.errors.locations?.[locationIndex]?.email}
                label="Email"
              />
              {formState.errors.locations?.[locationIndex]?.email && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.email
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.phoneNumber`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                defaultValue={locationData.phoneNumber}
                error={
                  !!formState.errors.locations?.[locationIndex]?.phoneNumber
                }
                label="Phone number"
              />
              {formState.errors.locations?.[locationIndex]?.phoneNumber && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.phoneNumber
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Category</Typography>
            </Grid>

            <Grid item xs={12}>
              {isDataLoading && !errorOnDataLoading && (
                <Box textAlign="center" width="100%">
                  <CircularProgress />
                </Box>
              )}
              {!isDataLoading && (
                <>
                  <TextField
                    {...register(`locations.${locationIndex}.category`, {
                      required: requiredFieldErrorMessage,
                    })}
                    fullWidth
                    select={
                      !errorOnDataLoading && businessCategories?.length > 0
                    }
                    defaultValue={locationData.category}
                    error={
                      !!formState.errors.locations?.[locationIndex]?.category
                    }
                    label="Category"
                  >
                    {!errorOnDataLoading
                      && businessCategories?.length > 0
                      && businessCategories.map((category) => (
                        <MenuItem key={category.name} value={category.name}>
                          {category.name.toUpperCase()}
                        </MenuItem>
                      ))}
                  </TextField>
                  {formState.errors.locations?.[locationIndex]?.category && (
                    <FieldErrorMessage>
                      {
                        formState.errors.locations?.[locationIndex]?.category
                          ?.message as string
                      }
                    </FieldErrorMessage>
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Address details</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.address`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                defaultValue={locationData.address}
                error={!!formState.errors.locations?.[locationIndex]?.address}
                label="Address"
              />
              {formState.errors.locations?.[locationIndex]?.address && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.address
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.shortAddress`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                defaultValue={locationData.shortAddress}
                error={
                  !!formState.errors.locations?.[locationIndex]?.shortAddress
                }
                label="Short address"
              />
              {formState.errors.locations?.[locationIndex]?.shortAddress && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.shortAddress
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.city`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                defaultValue={locationData.city}
                error={!!formState.errors.locations?.[locationIndex]?.city}
                label="City"
              />
              {formState.errors.locations?.[locationIndex]?.city && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.city
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.postalCode`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                defaultValue={locationData.postalCode}
                error={
                  !!formState.errors.locations?.[locationIndex]?.postalCode
                }
                label="Postal code"
              />
              {formState.errors.locations?.[locationIndex]?.postalCode && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.postalCode
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.county`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                defaultValue={locationData.county}
                error={
                  !!formState.errors.locations?.[locationIndex]?.county
                }
                label="County"
              />
              {formState.errors.locations?.[locationIndex]?.county && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.county
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              {isDataLoading && !errorOnDataLoading && (
                <Box textAlign="center" width="100%">
                  <CircularProgress />
                </Box>
              )}
              {!isDataLoading && (
                <>
                  <TextField
                    {...register(`locations.${locationIndex}.country`, {
                      required: requiredFieldErrorMessage,
                    })}
                    fullWidth
                    select={
                      !errorOnDataLoading && supportedCountries?.length > 0
                    }
                    defaultValue={locationData.country}
                    error={
                      !!formState.errors.locations?.[locationIndex]?.country
                    }
                    label="Country"
                  >
                    {!errorOnDataLoading
                      && supportedCountries?.length > 0
                      && supportedCountries.map((countryData) => (
                        <MenuItem
                          key={countryData.country}
                          value={countryData.country}
                        >
                          {`${countryData.country[0].toUpperCase()}${countryData.country.substring(
                            1,
                          )}`}
                        </MenuItem>
                      ))}
                  </TextField>
                  {formState.errors.locations?.[locationIndex]?.country && (
                    <FieldErrorMessage>
                      {
                        formState.errors.locations?.[locationIndex]?.country
                          ?.message as string
                      }
                    </FieldErrorMessage>
                  )}
                </>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.googlePlaceId`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                defaultValue={locationData.googlePlaceId || 0}
                error={
                  !!formState.errors.locations?.[locationIndex]?.googlePlaceId
                }
                label="Google place ID"
              />
              {formState.errors.locations?.[locationIndex]?.googlePlaceId && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.googlePlaceId
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.latitude`, {
                  required: requiredFieldErrorMessage,
                  valueAsNumber: true,
                })}
                fullWidth
                defaultValue={locationData.latitude || 0}
                error={!!formState.errors.locations?.[locationIndex]?.latitude}
                label="Latitude"
              />
              {formState.errors.locations?.[locationIndex]?.latitude && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.latitude
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.longitude`, {
                  required: requiredFieldErrorMessage,
                  valueAsNumber: true,
                })}
                fullWidth
                defaultValue={locationData.longitude || 0}
                error={!!formState.errors.locations?.[locationIndex]?.longitude}
                label="Longitude"
              />
              {formState.errors.locations?.[locationIndex]?.longitude && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.longitude
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <GoogleMapContainer
                lat={locationData.latitude}
                lng={locationData.longitude}
                zoom={17}
                enableMapOptions={false}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Opening hours</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.openingHours.Monday`, {
                  required: requiredFieldErrorMessage,
                  pattern: {
                    value: openingHoursPatternValidation,
                    message: openingHoursFormatErrorMessage,
                  },
                })}
                fullWidth
                defaultValue={locationData.openingHours.Monday}
                error={
                  !!formState.errors.locations?.[locationIndex]?.openingHours
                    ?.Monday
                }
                label="Monday"
              />
              {formState.errors.locations?.[locationIndex]?.openingHours?.Monday
                ?.message && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.openingHours
                      ?.Monday?.message
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(
                  `locations.${locationIndex}.openingHours.Tuesday`,
                  {
                    required: requiredFieldErrorMessage,
                    pattern: {
                      value: openingHoursPatternValidation,
                      message: openingHoursFormatErrorMessage,
                    },
                  },
                )}
                fullWidth
                defaultValue={locationData.openingHours.Tuesday}
                error={
                  !!formState.errors.locations?.[locationIndex]?.openingHours
                    ?.Tuesday
                }
                label="Tuesday"
              />
              {formState.errors.locations?.[locationIndex]?.openingHours
                ?.Tuesday?.message && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.openingHours
                      ?.Tuesday?.message
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(
                  `locations.${locationIndex}.openingHours.Wednesday`,
                  {
                    required: requiredFieldErrorMessage,
                    pattern: {
                      value: openingHoursPatternValidation,
                      message: openingHoursFormatErrorMessage,
                    },
                  },
                )}
                fullWidth
                defaultValue={locationData.openingHours.Wednesday}
                error={
                  !!formState.errors.locations?.[locationIndex]?.openingHours
                    ?.Wednesday
                }
                label="Wednesday"
              />
              {formState.errors.locations?.[locationIndex]?.openingHours
                ?.Wednesday?.message && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.openingHours
                      ?.Wednesday?.message
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(
                  `locations.${locationIndex}.openingHours.Thursday`,
                  {
                    required: requiredFieldErrorMessage,
                    pattern: {
                      value: openingHoursPatternValidation,
                      message: openingHoursFormatErrorMessage,
                    },
                  },
                )}
                fullWidth
                defaultValue={locationData.openingHours.Thursday}
                error={
                  !!formState.errors.locations?.[locationIndex]?.openingHours
                    ?.Thursday
                }
                label="Thursday"
              />
              {formState.errors.locations?.[locationIndex]?.openingHours
                ?.Thursday?.message && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.openingHours
                      ?.Thursday?.message
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.openingHours.Friday`, {
                  required: requiredFieldErrorMessage,
                  pattern: {
                    value: openingHoursPatternValidation,
                    message: openingHoursFormatErrorMessage,
                  },
                })}
                fullWidth
                defaultValue={locationData.openingHours.Friday}
                error={
                  !!formState.errors.locations?.[locationIndex]?.openingHours
                    ?.Friday
                }
                label="Friday"
              />
              {formState.errors.locations?.[locationIndex]?.openingHours?.Friday
                ?.message && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.openingHours
                      ?.Friday?.message
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(
                  `locations.${locationIndex}.openingHours.Saturday`,
                  {
                    required: requiredFieldErrorMessage,
                    pattern: {
                      value: openingHoursPatternValidation,
                      message: openingHoursFormatErrorMessage,
                    },
                  },
                )}
                fullWidth
                defaultValue={locationData.openingHours.Saturday}
                error={
                  !!formState.errors.locations?.[locationIndex]?.openingHours
                    ?.Saturday
                }
                label="Saturday"
              />
              {formState.errors.locations?.[locationIndex]?.openingHours
                ?.Saturday?.message && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.openingHours
                      ?.Saturday?.message
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`locations.${locationIndex}.openingHours.Sunday`, {
                  required: requiredFieldErrorMessage,
                  pattern: {
                    value: openingHoursPatternValidation,
                    message: openingHoursFormatErrorMessage,
                  },
                })}
                fullWidth
                defaultValue={locationData.openingHours.Sunday}
                error={
                  !!formState.errors.locations?.[locationIndex]?.openingHours
                    ?.Sunday
                }
                label="Sunday"
              />
              {formState.errors.locations?.[locationIndex]?.openingHours?.Sunday
                ?.message && (
                <FieldErrorMessage>
                  {
                    formState.errors.locations?.[locationIndex]?.openingHours
                      ?.Sunday?.message
                  }
                </FieldErrorMessage>
              )}
            </Grid>
          </Grid>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessApplicationLocationsCard;
