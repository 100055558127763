export type StripeCurrency = 'eur' | 'gbp' | 'aud' | 'cad' | 'aed' | 'usd';

export type BusinessApplication = {
  businessId: string;
  businessName: string;
  businessUserId: string;
  email: string;
  contact: string;
  phoneNumber: string;
  address: string;
  shortAddress: string;
  postalCode: string;
  city: string;
  country: string;
  county: string;
  latitude: number;
  longitude: number;
  googlePlaceId: string;
  applicationStatus: BusinessApplicationStatus;
  tagOrderStatus: BusinessApplicationTagOrderStatus;
  tagDeliveryToEachLocation?: boolean | null;
  tagDeliveryAddress?: string | null;
  tagDeliveryTrackingNumber?: string | null;
  businessProfile: BusinessProfile;
  posIntegration?: BusinessApplicationPosIntegration | null;
  locations: BusinessLocation[];
  schemes: LoyaltyScheme[];
  billingInformation: BusinessApplicationBillingInfo;
  campaigns: BusinessApplicationCampaign[];
  notes: BusinessApplicationNote[];
  tags: BusinessApplicationTag[];
  subscribers: string[];
  salesPerson?: string;
  category: string | null;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt?: string | null;
  deletedBy?: string | null;
};

export type BusinessApplicationCampaign = {
  schemeId: string;
  campaignType: 'birthday' | 'acquisition' | 'retention' | 'custom' | 'tag';
  rewardType: 'voucher' | 'stamp' | 'accelerator';
};

export type BusinessApplicationNote = {
  userName: string;
  createdAt: string;
  createdBy: string;
  content: string;
};

export type BusinessApplicationTag = {
  businessId: string;
  locationId: string;
  schemeId: string;
  tagPreEncodeId: number;
  tagId: string;
};

export type BusinessRegistration = {
  businessName: string;
  businessUserId: string;
  email: string;
  contact: string;
  phoneNumber: string;
  address: string;
  shortAddress: string;
  postalCode: string;
  city: string;
  country: string;
  latitude: number;
  longitude: number;
  googlePlaceId: string;
  numberOfLocations: number;
};

export type BusinessApplicationBillingInfo = {
  businessTaxId: string | null;
  currency: StripeCurrency | null;
  registeredBusinessName: string | null;
  stripeSetupIntentId: string | null;
  useVolumeBaseBilling: boolean | null;
  billingInterval: 'month' | 'year';
  stripeCustomerId: string | null;
  stripeDiscountIds: string[];
  stripeProductId: string | null;
  stripePriceId: string | null;
  stripeTaxRateId: string | null;
  stripePaymentMethodId: string | null;
  stripeInvoiceId: string | null;
  subscriptionStartingQuantity: number | null;
  applicationCoupon: string | null;
};

export type BusinessApplicationAuditLog = {
  id: number;
  businessId: string;
  operation: BusinessApplicationAuditLogOperation;
  previousState: BusinessApplication;
  currentState: BusinessApplication;
  createdAt: string;
  createdBy: string;
};

export enum BusinessApplicationStatus {
  APPLICATION_STARTED = 'APPLICATION_STARTED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  APPROVED = 'APPROVED',
  CHANGES_REQUIRED = 'CHANGES_REQUIRED',
  LIVE_ON_SQUID = 'LIVE_ON_SQUID',
}

export enum BusinessApplicationTagOrderStatus {
  NOT_PURCHASED = 'NOT_PURCHASED',
  PURCHASED = 'PURCHASED',
  POSTED = 'POSTED',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED',
}

export enum BusinessApplicationPosIntegration {
  SQUARE = 'SQUARE',
}

export enum BusinessApplicationAuditLogOperation {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export type LoyaltyScheme = {
  schemeId: string;
  businessId: string;
  type: LoyaltySchemeType;
  addStamp: boolean;
  rewardText: string;
  rewardTextPlural?: string;
  costPrice?: number;
  sellPrice?: number;
  images: string[];
  restrictions?: {
    period: number;
    amount: number;
  };
  posPointsConversion?: number;
  posLoyaltySchemeType?: SchemePosLoyaltySchemeType;
  posRewardType?: SchemePosRewardType;
  posProgramType?: SchemePosProgramType;
  internalDescription?: string | null;
  designImages: string[];
  enabledLocations: string[];
  max: number | null;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt?: string;
  deletedBy?: string;
};

export enum SchemePosLoyaltySchemeType {
  ALL = 'all',
  ITEM_SPECIFIC = 'itemSpecific',
}
export enum SchemePosRewardType {
  POINTS = 'points',
  STAMPS = 'stamps',
}
export enum SchemePosProgramType {
  SPEND_BASED = 'spendBased',
  QUANTITY_BASED = 'quantityBased',
}

export type BusinessLocation = {
  locationId: string;
  businessId: string;
  address?: string;
  shortAddress?: string;
  city?: string;
  postalCode?: string;
  email?: string;
  country?: string;
  county?: string;
  contact?: string;
  phoneNumber?: string;
  isLive: boolean;
  isTest: boolean;
  activationDatetime?: Date;
  churnDatetime?: Date;
  latitude: number;
  longitude: number;
  googlePlaceId?: string;
  openingHours: LocationOpeningHours;
  logo?: string | null;
  cover?: string | null;
  category?: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt?: string;
  deletedBy?: string;
};

export type LocationOpeningHours = {
  Monday?: string;
  Tuesday?: string;
  Wednesday?: string;
  Thursday?: string;
  Friday?: string;
  Saturday?: string;
  Sunday?: string;
};

export type LocationProfile = {
  locationId: string;
  description: string;
  logoImage: string;
  coverImage: string;
};

export type BusinessProfile = {
  businessId: string;
  description?: string | null;
  logoImage?: string | null;
  coverImage?: string | null;
  ourStoryDescription?: string | null;
  ourStoryImages: string[];
  ourSpaceDescription?: string | null;
  ourSpaceImages: string[];
  ourEthosDescription?: string | null;
  ourEthosImages: string[];
  ourTeamDescription?: string | null;
  ourTeamImages: string[];
  ourFoodDescription?: string | null;
  ourFoodImages: string[];
  ourFoodLinks: {
    menuLink?: string | null;
    orderLink?: string | null;
  };
  socialLinks: {
    website?: string | null;
    instagram?: string | null;
    youtube?: string | null;
    tiktok?: string | null;
    meta?: string | null;
    linkedin?: string | null;
    twitter?: string | null;
  };
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  deletedAt?: string | null;
  deletedBy?: string | null;
};

export type AllowedFileFolder =
  | 'logo'
  | 'cover'
  | 'stampcard'
  | 'stampcard-design'
  | 'story'
  | 'space'
  | 'ethos'
  | 'team'
  | 'food';

export type BusinessApplicationIssuesSummary = {
  issues: {
    [key: string]: BusinessApplicationIssue[]
  },
  count: number
};

export type BusinessApplicationIssue = {
  type: string;
  entity: 'tag' | 'location' | 'scheme' | 'business';
  entityId: string;
  message: string;
};

type FindAllBusinessApplicationsResponse = {
  data: BusinessApplication[];
};

type UpdateBusinessApplicationResponse = {
  data: BusinessApplication;
};

type GetBusinessApplicationAuditLogsResponse = {
  data: BusinessApplicationAuditLog[];
};

export const findAllBusinessApplications = async (
  abortController?: AbortController,
): Promise<FindAllBusinessApplicationsResponse> => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/businessApplications/v1/all`,
    {
      method: 'GET',
      signal: abortController?.signal,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return (await result.json()) as FindAllBusinessApplicationsResponse;
};

export const updateBusinessApplication = async (
  businessApplication: BusinessApplication,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessApplication.businessId}`,
    {
      method: 'PUT',
      signal: abortController?.signal,
      body: JSON.stringify({
        ...businessApplication,
        schemes: businessApplication.schemes.map((scheme) => ({
          ...scheme,
          enabledLocations: typeof scheme.enabledLocations === 'string'
            ? [scheme.enabledLocations]
            : scheme.enabledLocations,
        })),
        billingInformation: {
          ...businessApplication.billingInformation,
          useVolumeBaseBilling: Boolean(
            businessApplication.billingInformation.useVolumeBaseBilling,
          ),
          stripeDiscountIds:
            businessApplication.billingInformation.stripeDiscountIds.filter(
              (discount) => discount !== '_',
            ),
          applicationCoupon:
            businessApplication.billingInformation.applicationCoupon === '_'
              ? null
              : businessApplication.billingInformation.applicationCoupon,
          stripePriceId:
            businessApplication.billingInformation.stripePriceId === '_'
              ? null
              : businessApplication.billingInformation.stripePriceId,
          stripeProductId:
            businessApplication.billingInformation.stripeProductId === '_'
              ? null
              : businessApplication.billingInformation.stripeProductId,
        },
      } as BusinessApplication),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return (await result.json()) as UpdateBusinessApplicationResponse;
};

export const addBusinessApplicationNotes = async (
  businessId: string,
  content: string,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/note`,
    {
      method: 'POST',
      signal: abortController?.signal,
      body: JSON.stringify({
        content,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return (await result.json()) as UpdateBusinessApplicationResponse;
};

export const subscribeToBusinessApplication = async (
  businessId: string,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/subscribe`,
    {
      method: 'POST',
      signal: abortController?.signal,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return (await result.json()) as UpdateBusinessApplicationResponse;
};

export const unsubscribeToBusinessApplication = async (
  businessId: string,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/unsubscribe`,
    {
      method: 'POST',
      signal: abortController?.signal,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return (await result.json()) as UpdateBusinessApplicationResponse;
};

export const assignBusinessApplication = async (
  businessId: string,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/assign`,
    {
      method: 'POST',
      signal: abortController?.signal,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return (await result.json()) as UpdateBusinessApplicationResponse;
};

export const unassignBusinessApplication = async (
  businessId: string,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/unassign`,
    {
      method: 'POST',
      signal: abortController?.signal,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return (await result.json()) as UpdateBusinessApplicationResponse;
};

export const revertBusinessApplication = async (
  businessId: string,
  auditLogId: string | number,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/revert/${auditLogId}`,
    {
      method: 'PUT',
      signal: abortController?.signal,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return (await result.json()) as UpdateBusinessApplicationResponse;
};

export const getBusinessApplicationAuditLogsByBusinessId = async (
  businessId: string,
  abortController?: AbortController,
): Promise<GetBusinessApplicationAuditLogsResponse> => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/auditLogs`,
    {
      method: 'GET',
      signal: abortController?.signal,
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return (await result.json()) as GetBusinessApplicationAuditLogsResponse;
};

export const uploadBusinessApplicationFiles = async (
  files: File[],
  businessId: string,
  folder: AllowedFileFolder,
  schemeId?: string,
  imageIndex?: string | number,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const url = new URL(
    `${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/images/${folder}`,
  );
  url.search = new URLSearchParams({
    ...(schemeId ? { schemeId } : {}),
    ...(imageIndex ? { imageIndex: imageIndex.toString() } : {}),
  }).toString();

  const formData = new FormData();
  files.forEach((file) => {
    formData.append('image', file);
  });

  const result = await fetch(url, {
    method: 'POST',
    signal: abortController?.signal,
    body: formData,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('userToken')}`,
    },
  });

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return (await result.json()) as UpdateBusinessApplicationResponse;
};

export const addTagToBusinessApplication = async (
  businessId: string,
  locationId: string,
  schemeId: string,
  tagPreEncodeId: number,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/tag`,
    {
      method: 'POST',
      signal: abortController?.signal,
      body: JSON.stringify({
        locationId,
        schemeId,
        tagPreEncodeId,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return (await result.json()) as UpdateBusinessApplicationResponse;
};

export const deleteTagFromBusinessApplication = async (
  businessId: string,
  locationId: string,
  schemeId: string,
  tagId: string,
  tagPreEncodeId: number,
  abortController?: AbortController,
): Promise<UpdateBusinessApplicationResponse> => {
  const result = await fetch(
    `${process.env.REACT_APP_API_URL}/businessApplications/v1/${businessId}/tag`,
    {
      method: 'DELETE',
      signal: abortController?.signal,
      body: JSON.stringify({
        locationId,
        schemeId,
        tagId,
        tagPreEncodeId,
      }),
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${localStorage.getItem('userToken')}`,
        'Content-Type': 'application/json',
      },
    },
  );

  if (result.status !== 200) {
    throw result as unknown as Error;
  }

  return (await result.json()) as UpdateBusinessApplicationResponse;
};

export const isUserSubscribedToBusinessApplication = (
  businessApplication: BusinessApplication,
): boolean => businessApplication.subscribers?.includes(localStorage.getItem('userEmail')!);

export const isUserAssignedToBusinessApplication = (
  businessApplication: BusinessApplication,
): boolean => businessApplication.salesPerson === localStorage.getItem('userEmail')!;

export const generateBusinessApplicationLocation = (
  businessApplication: BusinessApplication,
  locationNumber: number,
): BusinessLocation => ({
  // Then we'll see if we need to replicate business application data or if we want to send back just an empty object
  email: businessApplication.email,
  isLive: false,
  isTest: false,
  address: businessApplication.address,
  contact: businessApplication.contact,
  country: businessApplication.country,
  latitude: 0,
  longitude: 0,
  businessId: businessApplication.businessId,
  locationId: `${businessApplication.businessId}-${locationNumber
    .toString()
    .padStart(3, '0')}`,
  phoneNumber: businessApplication.phoneNumber,
  openingHours: {
    Friday: '00:00-00:00',
    Monday: '00:00-00:00',
    Sunday: '00:00-00:00',
    Tuesday: '00:00-00:00',
    Saturday: '00:00-00:00',
    Thursday: '00:00-00:00',
    Wednesday: '00:00-00:00',
  },
  shortAddress: businessApplication.shortAddress,
  postalCode: businessApplication.postalCode,
  googlePlaceId: businessApplication.googlePlaceId,
  logo: businessApplication.businessProfile.logoImage,
  cover: businessApplication.businessProfile.logoImage,
  createdAt: new Date().toISOString(),
  createdBy: localStorage.getItem('userId')!,
  updatedAt: new Date().toISOString(),
  updatedBy: localStorage.getItem('userId')!,
});

export enum LoyaltySchemeType {
  STAMP_CARD = 'STAMP_CARD',
  POS = 'POS',
}

export const generateBusinessApplicationLoyaltyScheme = (
  businessApplication: BusinessApplication,
  schemeNumber: number,
): LoyaltyScheme => ({
  schemeId: `${businessApplication.businessId}${
    !schemeNumber ? '' : `_${schemeNumber}`
  }`,
  businessId: businessApplication.businessId,
  type: LoyaltySchemeType.STAMP_CARD,
  addStamp: false,
  rewardText: '1 Free coffee',
  restrictions: {
    amount: 1, // amount of stamps allowed within the period specified
    period: 1, // period in seconds
  },
  rewardTextPlural: '',
  costPrice: 0,
  sellPrice: 0,
  images: [],
  internalDescription: null,
  designImages: [],
  enabledLocations: [],
  max: 0,
  createdAt: new Date().toISOString(),
  createdBy: localStorage.getItem('userId')!,
  updatedAt: new Date().toISOString(),
  updatedBy: localStorage.getItem('userId')!,
});

export const findBusinessApplicationByBusinessId = (
  businessId: string,
): Promise<BusinessApplication> => ({ businessId } as unknown as Promise<BusinessApplication>);

export const findBusinessApplicationByUserId = (
  userId: string,
): Promise<BusinessApplication> => ({ userId } as unknown as Promise<BusinessApplication>);

export const createBusinessApplication = (
  businessRegistration: BusinessRegistration,
): Promise<BusinessApplication> => ({ businessRegistration } as unknown as Promise<BusinessApplication>);

export const deleteBusinessApplication = (
  businessId: string,
  userId: string,
): Promise<void> => ({ businessId, userId } as unknown as Promise<void>);

export const getBusinessApplicationOrphanTags = (
  businessApplication: BusinessApplication,
) => {
  if (!businessApplication.tags || businessApplication.tags.length === 0) {
    return [];
  }

  return businessApplication.tags.filter((tag) => {
    const hasLocation = businessApplication.locations.find(
      (location) => location.locationId === tag.locationId,
    );
    const hasScheme = businessApplication.schemes.find(
      (scheme) => scheme.schemeId === tag.schemeId,
    );

    return !hasLocation || !hasScheme;
  });
};

export const getBusinessApplicationLocationsWithWrongFieldValues = (
  businessApplication: BusinessApplication,
) => {
  if (!businessApplication.locations || businessApplication.locations.length === 0) {
    return [];
  }

  return businessApplication.locations.filter(
    (location) => !location.address
        || !location.shortAddress
        || !location.country
        || !location.city
        || !location.postalCode
        || !location.phoneNumber
        || !location.contact
        || !location.email
        || !location.category
        || !location.county
        || !location.googlePlaceId,
  );
};

export const getBusinessApplicationSchemeWithWrongFieldValues = (
  businessApplication: BusinessApplication,
) => {
  if (
    !businessApplication.schemes
    || businessApplication.schemes.length === 0
  ) {
    return [];
  }

  const locationIds = businessApplication.locations.map(
    (location) => location.locationId,
  );
  return businessApplication.schemes.filter(
    (scheme) => (!scheme.enabledLocations.includes('ALL')
        && !scheme?.enabledLocations?.every((locationId) => locationIds.includes(locationId)))
      || (scheme.type === 'POS' ? scheme.images.length !== 1 : scheme.max !== scheme.images.length),
  );
};

export const getBusinessApplicationGeneralInformationIssues = (
  businessApplication: BusinessApplication,
): BusinessApplicationIssue[] => {
  const areGeneralInfoMissing = !(businessApplication.address
    && businessApplication.shortAddress
    && businessApplication.country
    && businessApplication.city
    && businessApplication.postalCode
    && businessApplication.county
    && businessApplication.latitude
    && businessApplication.longitude
    && businessApplication.googlePlaceId
    && businessApplication.contact
    && businessApplication.email
    && businessApplication.phoneNumber
    && businessApplication.category) ? [
      {
        type: 'business_data',
        entity: 'business' as BusinessApplicationIssue['entity'],
        entityId: businessApplication.businessId,
        message: 'The business application is missing mandatory values',
      },
    ] : [];

  const isSalesPersonMissing = !businessApplication.salesPerson
    ? [
      {
        type: 'business_data',
        entity: 'business' as BusinessApplicationIssue['entity'],
        entityId: businessApplication.businessId,
        message: 'The business application has no sales person assigned',
      },
    ]
    : [];

  return [...areGeneralInfoMissing, ...isSalesPersonMissing];
};

export const computeBusinessApplicationIssuesSummary = (
  businessApplication: BusinessApplication,
): BusinessApplicationIssuesSummary => {
  const business = getBusinessApplicationGeneralInformationIssues(businessApplication);

  const tags: BusinessApplicationIssue[] = getBusinessApplicationOrphanTags(businessApplication).map((orphanTag) => ({
    type: 'orphan_tag',
    entity: 'tag',
    entityId: orphanTag.tagId,
    message: 'The tag is linked to a scheme or location that does not exists',
  }));

  const locations: BusinessApplicationIssue[] = getBusinessApplicationLocationsWithWrongFieldValues(
    businessApplication,
  ).map((location) => ({
    type: 'location_data',
    entity: 'location',
    entityId: location.locationId,
    message: 'The location is missing mandatory values',
  }));

  const schemes: BusinessApplicationIssue[] = getBusinessApplicationSchemeWithWrongFieldValues(businessApplication).map(
    (scheme) => (scheme.max !== scheme.images.length
      ? {
        type: 'location_data',
        entity: 'location',
        entityId: scheme.schemeId,
        message:
              'The number of scheme images uploaded is not correct',
      }
      : {
        type: 'location_data',
        entity: 'location',
        entityId: scheme.schemeId,
        message:
              'One of the enabled locations does not exists',
      }),
  );

  return {
    issues: {
      business,
      locations,
      schemes,
      tags,
    },
    count: tags.length + locations.length + schemes.length,
  };
};
