import { useEffect, useState } from 'react';
import { getAllStripeAllowedProduct, StripeAllowedProduct, SupportedCountry } from '../../../Services/BillingService';
import { BusinessCategory, getBusinessCategories } from '../../../Services/BusinessService';

const useGetBusinessApplicationSettings = (refresh?: boolean) => {
  const [stripeProducts, setStripeProducts] = useState<
  StripeAllowedProduct[]
  >([]);
  const [supportedCountries, setSupportedCountries] = useState<SupportedCountry[]>([]);
  const [businessCategories, setBusinessCategories] = useState<BusinessCategory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      try {
        setError(false);
        setIsLoading(true);
        const [productAndCountryResult, categoryResult] = await Promise.all([
          getAllStripeAllowedProduct(
            abortController,
          ),
          getBusinessCategories(
            abortController,
          ),
        ]);
        setStripeProducts(productAndCountryResult.data.stripeProducts || []);
        setSupportedCountries(productAndCountryResult.data.supportedCountries);
        setBusinessCategories(categoryResult.data);
      } catch (e: any) {
        if (e.name !== 'AbortError') {
          setError(true);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => abortController?.abort();
  }, [refresh]);

  return {
    stripeProducts,
    supportedCountries,
    businessCategories,
    isLoading,
    error,
  };
};

export default useGetBusinessApplicationSettings;
