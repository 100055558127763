import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Card,
  Collapse,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { AiFillDelete } from 'react-icons/ai';
import { MdExpandMore } from 'react-icons/md';
import {
  BusinessApplicationImageUploadButton,
  CardTitle,
  CardTitleText,
  ExpandedContent,
  ExpandMore,
  FieldErrorMessage,
  MultiImagesPreview,
  StyledIconButton,
} from './shared';
import {
  AllowedFileFolder,
  BusinessApplication,
  LoyaltyScheme,
  LoyaltySchemeType,
  SchemePosLoyaltySchemeType,
  SchemePosProgramType,
  SchemePosRewardType,
} from '../../../Services/BusinessApplicationService';
import { requiredFieldErrorMessage } from '../utils';

function BusinessApplicationSchemesCard({
  businessApplication,
  schemeData,
  schemeIndex,
  schemesCount,
  isLoading,
  onRemoveSchemeHandler,
  handleFilesUploadSubmit,
}: Readonly<{
  businessApplication: BusinessApplication;
  schemeData: LoyaltyScheme;
  schemeIndex: number;
  schemesCount: number;
  isLoading: boolean;
  onRemoveSchemeHandler: (schemeIndex: number) => void;
  handleFilesUploadSubmit: (
    files: File[],
    businessId: string,
    folder: AllowedFileFolder,
    schemeId?: string,
    imageIndex?: string | number,
  ) => Promise<void>;
}>) {
  const {
    register, formState, watch, unregister,
  } = useFormContext<{
    schemes: LoyaltyScheme[];
  }>();

  const [isExpanded, setIsExpanded] = useState(false);
  const [schemeMaxChangeWarning, setShowSchemeMaxChangeWarning] = useState(false);
  const schemeType = watch(`schemes.${schemeIndex}.type`);
  const schemeMax = watch(`schemes.${schemeIndex}.max`);

  useEffect(() => {
    if (schemeType === LoyaltySchemeType.STAMP_CARD) {
      unregister(`schemes.${schemeIndex}.posPointsConversion`);
      unregister(`schemes.${schemeIndex}.posLoyaltySchemeType`);
      unregister(`schemes.${schemeIndex}.posRewardType`);
      unregister(`schemes.${schemeIndex}.posProgramType`);
    }
  }, [schemeType]);

  useEffect(() => {
    if (
      formState.dirtyFields.schemes?.find((field) => field?.max)
      && schemeData.images.length > 0
      && (schemeMax || 0) !== schemeData.images.length) {
      setShowSchemeMaxChangeWarning(true);
    }
  }, [schemeMax]);

  return (
    <Card
      sx={{
        width: '100%',
        '&:not(:first-of-type)': {
          marginTop: '15px',
        },
      }}
    >
      <CardTitle>
        <CardTitleText variant="h4">{schemeData.schemeId}</CardTitleText>
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <StyledIconButton
            size="small"
            disabled={schemesCount === 1 || isLoading}
            onClick={() => onRemoveSchemeHandler(schemeIndex)}
          >
            <AiFillDelete />
          </StyledIconButton>
          <ExpandMore
            expand={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <MdExpandMore />
          </ExpandMore>
        </div>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <Grid container gap={3} alignItems="center">
            <Grid item xs={12}>
              <Typography variant="h4">General details</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.schemeId`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                defaultValue={schemeData.schemeId}
                error={!!formState.errors.schemes?.[schemeIndex]?.schemeId}
                label="Scheme ID"
              />
              {formState.errors.schemes?.[schemeIndex]?.schemeId && (
                <FieldErrorMessage>
                  {
                    formState.errors.schemes?.[schemeIndex]?.schemeId
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.internalDescription`)}
                fullWidth
                multiline
                minRows={3}
                defaultValue={schemeData.internalDescription}
                error={
                  !!formState.errors.schemes?.[schemeIndex]?.internalDescription
                }
                label="Internal scheme description"
              />
              {formState.errors.schemes?.[schemeIndex]?.internalDescription && (
                <FieldErrorMessage>
                  {
                    formState.errors.schemes?.[schemeIndex]?.internalDescription
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Max Stamps/Points</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.max`, {
                  required: requiredFieldErrorMessage,
                  valueAsNumber: true,
                  min: {
                    value: 1,
                    message: 'The scheme max stamps/points cannot be 0',
                  },
                })}
                fullWidth
                defaultValue={schemeData.max}
                type="number"
                error={!!formState.errors.schemes?.[schemeIndex]?.max}
                label="Max Stamps/Points"
              />
              {formState.errors.schemes?.[schemeIndex]?.max && (
                <FieldErrorMessage>
                  {
                    formState.errors.schemes?.[schemeIndex]?.max
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            {schemeMaxChangeWarning && (
              <Grid item xs={12}>
                <Typography align="center" paddingY="10px" color="error">
                  You will need to re-upload the scheme images
                  if you change the max stamps/points value after a set of
                  images have been already uploaded.
                </Typography>
                <Typography align="center" paddingY="10px" color="error">
                  {`For "POS" schemes you will need just 1 image while for "Stamp Card" schemes
                      you will need to upload the same number set in "Max Stamps/Points" field.`}
                </Typography>
                <Typography align="center" paddingY="10px" color="error">
                  You will not be able to approve this business application if
                  there is a mismatch between the number of images uploaded
                  and the max stamps/points value.
                </Typography>
              </Grid>
            )}

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Scheme type</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.type`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                select
                error={!!formState.errors.schemes?.[schemeIndex]?.type}
                defaultValue={schemeData.type}
                label="Scheme Type"
              >
                <MenuItem value={LoyaltySchemeType.STAMP_CARD}>
                  STAMP CARD
                </MenuItem>
                <MenuItem value={LoyaltySchemeType.POS}>POS</MenuItem>
              </TextField>
              {formState.errors.schemes?.[schemeIndex]?.type && (
                <FieldErrorMessage>
                  {
                    (formState.errors.schemes?.[schemeIndex]?.type as any)
                      .message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Allowed locations</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.enabledLocations`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                select
                error={
                  !!formState.errors.schemes?.[schemeIndex]?.enabledLocations
                }
                defaultValue={schemeData.enabledLocations}
                label="Enabled locations"
              >
                <MenuItem value="ALL">ALL</MenuItem>
                {businessApplication.locations.map((loc) => (
                  <MenuItem key={loc.locationId} value={loc.locationId}>
                    {loc.locationId}
                  </MenuItem>
                ))}
              </TextField>
              {formState.errors.schemes?.[schemeIndex]?.enabledLocations && (
                <FieldErrorMessage>
                  {
                    formState.errors.schemes?.[schemeIndex]?.enabledLocations
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Restrictions</Typography>
              <Typography variant="subtitle1">
                Restriction will stop multiple stamps in quick succession.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.restrictions.amount`, {
                  required: requiredFieldErrorMessage,
                  valueAsNumber: true,
                })}
                fullWidth
                defaultValue={schemeData.restrictions?.amount}
                error={
                  !!formState.errors.schemes?.[schemeIndex]?.restrictions
                    ?.amount
                }
                label="The Amount specifies how many taps before timeout"
              />
              {formState.errors.schemes?.[schemeIndex]?.restrictions
                ?.amount && (
                <FieldErrorMessage>
                  {
                    formState.errors.schemes?.[schemeIndex]?.restrictions
                      ?.amount?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.restrictions.period`, {
                  required: requiredFieldErrorMessage,
                  valueAsNumber: true,
                })}
                fullWidth
                defaultValue={schemeData.restrictions?.period}
                error={
                  !!formState.errors.schemes?.[schemeIndex]?.restrictions
                    ?.period
                }
                label="The Period (seconds) specifies how many seconds until they can tap again"
              />
              {formState.errors.schemes?.[schemeIndex]?.restrictions
                ?.period && (
                <FieldErrorMessage>
                  {
                    formState.errors.schemes?.[schemeIndex]?.restrictions
                      ?.period?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Reward</Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.addStamp`, {
                  setValueAs: (v) => Boolean(v),
                })}
                fullWidth
                select
                defaultValue={Number(schemeData.addStamp || 0)}
                label="Add stamp on tap"
              >
                <MenuItem value={1}>Yes</MenuItem>
                <MenuItem value={0}>No</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.rewardText`, {
                  required: requiredFieldErrorMessage,
                })}
                fullWidth
                defaultValue={schemeData.rewardText}
                error={!!formState.errors.schemes?.[schemeIndex]?.rewardText}
                label="Reward text"
              />
              {formState.errors.schemes?.[schemeIndex]?.rewardText && (
                <FieldErrorMessage>
                  {
                    formState.errors.schemes?.[schemeIndex]?.rewardText
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.rewardTextPlural`)}
                fullWidth
                defaultValue={schemeData.schemeId}
                error={
                  !!formState.errors.schemes?.[schemeIndex]?.rewardTextPlural
                }
                label="Reward text plural"
              />
              {formState.errors.schemes?.[schemeIndex]?.rewardTextPlural && (
                <FieldErrorMessage>
                  {
                    formState.errors.schemes?.[schemeIndex]?.rewardTextPlural
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Prices - ROI settings</Typography>
              <Typography variant="subtitle1">
                ROI is calculated by using the number of stamps and multiple by
                the Sell Price minus the in house Cost Price.
              </Typography>
              <Typography variant="subtitle1">
                ROI = (Stamps * Sell Price) - (Stamps * Cost Price)
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.costPrice`, {
                  valueAsNumber: true,
                })}
                inputProps={{
                  step: '0.01',
                }}
                fullWidth
                defaultValue={schemeData.costPrice}
                type="number"
                error={!!formState.errors.schemes?.[schemeIndex]?.costPrice}
                label="Cost price"
              />
              {formState.errors.schemes?.[schemeIndex]?.costPrice && (
                <FieldErrorMessage>
                  {
                    formState.errors.schemes?.[schemeIndex]?.costPrice
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                {...register(`schemes.${schemeIndex}.sellPrice`, {
                  valueAsNumber: true,
                })}
                inputProps={{
                  step: '0.01',
                }}
                fullWidth
                defaultValue={schemeData.sellPrice}
                type="number"
                error={!!formState.errors.schemes?.[schemeIndex]?.sellPrice}
                label="Sell price"
              />
              {formState.errors.schemes?.[schemeIndex]?.sellPrice && (
                <FieldErrorMessage>
                  {
                    formState.errors.schemes?.[schemeIndex]?.sellPrice
                      ?.message as string
                  }
                </FieldErrorMessage>
              )}
            </Grid>

            {schemeType === LoyaltySchemeType.POS && (
              <>
                <Grid item xs={12}>
                  <Divider style={{ margin: '25px 0' }} />
                  <Typography variant="h4">POS Scheme details</Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    {...register(`schemes.${schemeIndex}.posPointsConversion`, {
                      required: requiredFieldErrorMessage,
                      valueAsNumber: true,
                    })}
                    fullWidth
                    defaultValue={schemeData.posPointsConversion || 1}
                    type="number"
                    error={
                      !!formState.errors.schemes?.[schemeIndex]
                        ?.posPointsConversion
                    }
                    label="POS points conversion"
                  />
                  {formState.errors.schemes?.[schemeIndex]
                    ?.posPointsConversion && (
                    <FieldErrorMessage>
                      {
                        formState.errors.schemes?.[schemeIndex]
                          ?.posPointsConversion?.message as string
                      }
                    </FieldErrorMessage>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    {...register(`schemes.${schemeIndex}.posLoyaltySchemeType`)}
                    fullWidth
                    select
                    defaultValue={
                      schemeData.posLoyaltySchemeType
                      || SchemePosLoyaltySchemeType.ALL
                    }
                    label="POS Scheme type"
                  >
                    <MenuItem value={SchemePosLoyaltySchemeType.ALL}>
                      ALL
                    </MenuItem>
                    <MenuItem value={SchemePosLoyaltySchemeType.ITEM_SPECIFIC}>
                      ITEM SPECIFIC
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    {...register(`schemes.${schemeIndex}.posRewardType`)}
                    fullWidth
                    select
                    defaultValue={
                      schemeData.posRewardType || SchemePosRewardType.POINTS
                    }
                    label="Reward type"
                  >
                    <MenuItem value={SchemePosRewardType.POINTS}>
                      POINTS
                    </MenuItem>
                    <MenuItem value={SchemePosRewardType.STAMPS}>
                      STAMPS
                    </MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    {...register(`schemes.${schemeIndex}.posProgramType`)}
                    fullWidth
                    select
                    defaultValue={
                      schemeData.posProgramType
                      || SchemePosProgramType.SPEND_BASED
                    }
                    label="Program type"
                  >
                    <MenuItem value={SchemePosProgramType.SPEND_BASED}>
                      SPEND BASED
                    </MenuItem>
                    <MenuItem value={SchemePosProgramType.QUANTITY_BASED}>
                      QUANTITY BASED
                    </MenuItem>
                  </TextField>
                </Grid>
              </>
            )}

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Scheme design images</Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <MultiImagesPreview
                images={schemeData.designImages}
                noImageChild="No scheme design images"
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <BusinessApplicationImageUploadButton
                buttonLabel="Upload scheme design images"
                businessApplication={businessApplication}
                folder="stampcard-design"
                schemeId={schemeData.schemeId}
                multipleFilesUpload
                buttonFullWidth
                handleFilesUploadSubmit={handleFilesUploadSubmit}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider style={{ margin: '25px 0' }} />
              <Typography variant="h4">Scheme images</Typography>
              <Typography>
                The number of images uploaded needs to match the number of the
                max stamps set above. For POS schemes, only 1 image is required.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <MultiImagesPreview
                images={schemeData.images}
                noImageChild="No scheme images"
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              {schemeMax ? (
                <BusinessApplicationImageUploadButton
                  buttonLabel="Upload scheme images"
                  businessApplication={businessApplication}
                  folder="stampcard"
                  schemeId={schemeData.schemeId}
                  multipleFilesUpload
                  buttonFullWidth
                  numberOfFilesToUpload={
                    schemeType !== 'POS' ? schemeMax : 1
                  }
                  handleFilesUploadSubmit={handleFilesUploadSubmit}
                />
              ) : (
                <Typography textAlign="center" color="error" variant="body2">
                  Before being able to upload scheme related images you need to
                  set the Max Stamps/Points for this scheme
                </Typography>
              )}
            </Grid>
          </Grid>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessApplicationSchemesCard;
