import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { MdExpandMore } from 'react-icons/md';
import {
  BusinessApplication,
  BusinessApplicationIssuesSummary,
  BusinessApplicationPosIntegration,
  BusinessApplicationStatus,
  updateBusinessApplication,
} from '../../../Services/BusinessApplicationService';
import {
  BusinessApplicationIssueSummary,
  CardTitle,
  CardTitleText,
  ExpandedContent,
  ExpandMore,
  FieldErrorMessage,
  StyledButton,
} from './shared';
import GoogleMapContainer from '../../../Components/BasicComponents/GoogleMapContainer';
import { getBusinessApplicationStatusChipColor, requiredFieldErrorMessage } from '../utils';
import { SupportedCountry } from '../../../Services/BillingService';
import { BusinessCategory } from '../../../Services/BusinessService';

type BusinessApplicationGeneralDetailsData = Pick<
BusinessApplication,
| 'applicationStatus'
| 'businessId'
| 'businessName'
| 'businessUserId'
| 'email'
| 'contact'
| 'phoneNumber'
| 'address'
| 'shortAddress'
| 'postalCode'
| 'city'
| 'country'
| 'county'
| 'latitude'
| 'longitude'
| 'googlePlaceId'
| 'posIntegration'
| 'category'
>;

function BusinessApplicationGeneralDetails({
  businessApplication,
  businessApplicationIssuesSummary,
  isDataLoading,
  errorOnDataLoading,
  supportedCountries,
  businessCategories,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication;
  businessApplicationIssuesSummary: BusinessApplicationIssuesSummary;
  isDataLoading: boolean;
  errorOnDataLoading: boolean;
  supportedCountries: SupportedCountry[];
  businessCategories: BusinessCategory[];
  onSetBusinessApplicationToEditHandler: (
    businessApplication: BusinessApplication
  ) => void;
}>) {
  const { register, handleSubmit, formState } = useForm<BusinessApplicationGeneralDetailsData>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isUpdateGeneralDetailsLoading, setIsUpdateGeneralDetailsLoading] = useState(false);
  const [isApproveLoading, setIsApproveLoading] = useState(false);
  const [isRequestChangesLoading, setIsRequestChangesLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isChangeStatusError, setIsChangeStatusError] = useState(false);
  const disableStatusChangeButtons = isRequestChangesLoading
    || isApproveLoading
    || isUpdateGeneralDetailsLoading
    || businessApplicationIssuesSummary?.count > 0;

  const onFormSubmitHandler = async (
    data: BusinessApplicationGeneralDetailsData,
  ) => {
    try {
      setError(false);
      setIsUpdateGeneralDetailsLoading(true);
      const result = await updateBusinessApplication({
        ...businessApplication,
        ...data,
        posIntegration:
          !data?.posIntegration || (data?.posIntegration as string) === '_'
            ? null
            : data?.posIntegration,
      });
      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setError(true);
    } finally {
      setIsUpdateGeneralDetailsLoading(false);
    }
  };

  const approveBusinessApplication = async () => {
    try {
      setIsChangeStatusError(false);
      setIsApproveLoading(true);
      const result = await updateBusinessApplication({
        ...businessApplication,
        applicationStatus: BusinessApplicationStatus.APPROVED,
      });
      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setIsChangeStatusError(true);
    } finally {
      setIsApproveLoading(false);
    }
  };

  const requestChangesToBusinessApplication = async () => {
    try {
      setIsChangeStatusError(false);
      setIsRequestChangesLoading(true);
      const result = await updateBusinessApplication({
        ...businessApplication,
        applicationStatus: BusinessApplicationStatus.CHANGES_REQUIRED,
      });
      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setIsChangeStatusError(true);
    } finally {
      setIsRequestChangesLoading(false);
    }
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardTitle>
        <CardTitleText variant="h4">General details</CardTitleText>
        <ExpandMore
          expand={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
          aria-expanded={isExpanded}
          aria-label="show more"
        >
          <MdExpandMore />
        </ExpandMore>
      </CardTitle>
      <Collapse in={isExpanded}>
        <ExpandedContent>
          <form onSubmit={handleSubmit(onFormSubmitHandler)}>
            <Grid container spacing={2} alignItems="center" paddingTop="25px">
              <Grid item xs={12} sm={6}>
                <StyledButton
                  onClick={() => approveBusinessApplication()}
                  disabled={disableStatusChangeButtons}
                  withChildComponent={isApproveLoading}
                  fullWidth
                >
                  {isApproveLoading ? (
                    <CircularProgress size="20px" />
                  ) : (
                    'Approve business application'
                  )}
                </StyledButton>
              </Grid>

              <Grid item xs={12} sm={6}>
                <StyledButton
                  onClick={() => requestChangesToBusinessApplication()}
                  disabled={isRequestChangesLoading
                    || isApproveLoading
                    || isUpdateGeneralDetailsLoading}
                  withChildComponent={isRequestChangesLoading}
                  isDelete
                  fullWidth
                >
                  {isRequestChangesLoading ? (
                    <CircularProgress size="20px" />
                  ) : (
                    'Request changes'
                  )}
                </StyledButton>
              </Grid>

              {isChangeStatusError && (
                <Grid item xs={12}>
                  <Typography
                    variant="body2"
                    color="error"
                    textAlign="center"
                    marginTop="15px"
                  >
                    It was not possible to update the business application
                    status, please try again.
                  </Typography>
                </Grid>
              )}
              {businessApplicationIssuesSummary?.count > 0 && (
                <BusinessApplicationIssueSummary
                  businessApplicationIssuesSummary={
                    businessApplicationIssuesSummary
                  }
                />
              )}

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">
                  Business application status
                </Typography>
              </Grid>

              <Grid item xs={12} textAlign="center">
                <Chip
                  sx={{ width: '100%' }}
                  label={businessApplication.applicationStatus}
                  color={getBusinessApplicationStatusChipColor(
                    businessApplication.applicationStatus,
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Basic information</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('businessName', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  defaultValue={businessApplication.businessName}
                  error={!!formState.errors.businessName}
                  label="Business Name"
                />
                {formState.errors?.businessName && (
                  <FieldErrorMessage>
                    {formState.errors?.businessName?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('businessUserId', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  defaultValue={businessApplication.businessUserId}
                  error={!!formState.errors.businessUserId}
                  label="User ID"
                />
                {formState.errors?.businessUserId && (
                  <FieldErrorMessage>
                    {formState.errors?.businessUserId?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('email', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  defaultValue={businessApplication.email}
                  error={!!formState.errors.email}
                  label="Email"
                />
                {formState.errors?.email && (
                  <FieldErrorMessage>
                    {formState.errors?.email?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('contact', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  defaultValue={businessApplication.contact}
                  error={!!formState.errors.contact}
                  label="Contact"
                />
                {formState.errors?.contact && (
                  <FieldErrorMessage>
                    {formState.errors?.contact?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('phoneNumber', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  defaultValue={businessApplication.phoneNumber}
                  error={!!formState.errors.phoneNumber}
                  label="Phone number"
                />
                {formState.errors?.phoneNumber && (
                  <FieldErrorMessage>
                    {formState.errors?.phoneNumber?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Category</Typography>
              </Grid>

              <Grid item xs={12}>
                {isDataLoading && !errorOnDataLoading && (
                  <Box textAlign="center" width="100%">
                    <CircularProgress />
                  </Box>
                )}
                {!isDataLoading && (
                  <>
                    <TextField
                      {...register('category', {
                        required: requiredFieldErrorMessage,
                      })}
                      fullWidth
                      select={
                        !errorOnDataLoading && businessCategories?.length > 0
                      }
                      defaultValue={businessApplication.category}
                      error={!!formState.errors.category}
                      label="Category"
                    >
                      {!errorOnDataLoading
                        && businessCategories?.length > 0
                        && businessCategories.map((category) => (
                          <MenuItem
                            key={category.name}
                            value={category.name}
                          >
                            {category.name.toUpperCase()}
                          </MenuItem>
                        ))}
                    </TextField>
                    {formState.errors?.category && (
                      <FieldErrorMessage>
                        {formState.errors?.category?.message as string}
                      </FieldErrorMessage>
                    )}
                  </>
                )}
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Address details</Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('address', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  defaultValue={businessApplication.address}
                  error={!!formState.errors.address}
                  label="Address"
                />
                {formState.errors?.address && (
                  <FieldErrorMessage>
                    {formState.errors?.address?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('shortAddress', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  defaultValue={businessApplication.shortAddress}
                  error={!!formState.errors.shortAddress}
                  label="Short address"
                />
                {formState.errors?.shortAddress && (
                  <FieldErrorMessage>
                    {formState.errors?.shortAddress?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('postalCode', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  defaultValue={businessApplication.postalCode}
                  error={!!formState.errors.postalCode}
                  label="Postal code"
                />
                {formState.errors?.postalCode && (
                  <FieldErrorMessage>
                    {formState.errors?.postalCode?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('city', { required: requiredFieldErrorMessage })}
                  fullWidth
                  defaultValue={businessApplication.city}
                  error={!!formState.errors.city}
                  label="City"
                />
                {formState.errors?.city && (
                  <FieldErrorMessage>
                    {formState.errors?.city?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('county', { required: requiredFieldErrorMessage })}
                  fullWidth
                  defaultValue={businessApplication.county}
                  error={!!formState.errors.county}
                  label="County"
                />
                {formState.errors?.county && (
                  <FieldErrorMessage>
                    {formState.errors?.county?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                {isDataLoading && !errorOnDataLoading && (
                  <Box textAlign="center" width="100%">
                    <CircularProgress />
                  </Box>
                )}
                {!isDataLoading && (
                  <>
                    <TextField
                      {...register('country', {
                        required: requiredFieldErrorMessage,
                      })}
                      fullWidth
                      select={
                        !errorOnDataLoading && supportedCountries?.length > 0
                      }
                      defaultValue={businessApplication.country?.toLowerCase()}
                      error={!!formState.errors.country}
                      label="Country"
                    >
                      {!errorOnDataLoading
                        && supportedCountries?.length > 0
                        && supportedCountries.map((countryData) => (
                          <MenuItem
                            key={countryData.country}
                            value={countryData.country}
                          >
                            {`${countryData.country[0].toUpperCase()}${countryData.country.substring(
                              1,
                            )}`}
                          </MenuItem>
                        ))}
                    </TextField>
                    {formState.errors?.country && (
                      <FieldErrorMessage>
                        {formState.errors?.country?.message as string}
                      </FieldErrorMessage>
                    )}
                  </>
                )}
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  {...register('googlePlaceId', {
                    required: requiredFieldErrorMessage,
                  })}
                  fullWidth
                  defaultValue={businessApplication.googlePlaceId || 0}
                  error={!!formState.errors.googlePlaceId}
                  label="Google place ID"
                />
                {formState.errors?.googlePlaceId && (
                  <FieldErrorMessage>
                    {formState.errors?.googlePlaceId?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('latitude', {
                    required: requiredFieldErrorMessage,
                    valueAsNumber: true,
                  })}
                  fullWidth
                  defaultValue={businessApplication.latitude || 0}
                  error={!!formState.errors.latitude}
                  label="Latitude"
                />
                {formState.errors?.latitude && (
                  <FieldErrorMessage>
                    {formState.errors?.latitude?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  {...register('longitude', {
                    required: requiredFieldErrorMessage,
                    valueAsNumber: true,
                  })}
                  fullWidth
                  defaultValue={businessApplication.longitude || 0}
                  error={!!formState.errors.longitude}
                  label="Longitude"
                />
                {formState.errors?.longitude && (
                  <FieldErrorMessage>
                    {formState.errors?.longitude?.message as string}
                  </FieldErrorMessage>
                )}
              </Grid>

              <Grid item xs={12}>
                <GoogleMapContainer
                  lat={businessApplication.latitude}
                  lng={businessApplication.longitude}
                  zoom={17}
                  enableMapOptions={false}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider style={{ margin: '25px 0' }} />
                <Typography variant="h4">Integrations</Typography>
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  {...register('posIntegration')}
                  fullWidth
                  select
                  defaultValue={businessApplication.posIntegration || '_'}
                  label="Set POS provider"
                >
                  <MenuItem value={BusinessApplicationPosIntegration.SQUARE}>
                    Square
                  </MenuItem>
                  <MenuItem value="_">NO POS INTEGRATIONS</MenuItem>
                </TextField>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                sx={{ marginTop: '20px', textAlign: 'center' }}
              >
                <StyledButton
                  disabled={
                    isRequestChangesLoading
                    || isApproveLoading
                    || isUpdateGeneralDetailsLoading
                  }
                  fullWidth
                  type="submit"
                  withChildComponent={isUpdateGeneralDetailsLoading}
                >
                  {isUpdateGeneralDetailsLoading ? (
                    <CircularProgress size="20px" />
                  ) : (
                    'Save changes'
                  )}
                </StyledButton>
                {error && (
                  <Typography
                    variant="body2"
                    color="error"
                    textAlign="center"
                    marginTop="15px"
                  >
                    Something went wrong while submitting the update, please try
                    again
                  </Typography>
                )}
              </Grid>
            </Grid>
          </form>
        </ExpandedContent>
      </Collapse>
    </Card>
  );
}

export default BusinessApplicationGeneralDetails;
