import React, { useEffect, useState } from 'react';
import {
  CircularProgress,
  Grid,
} from '@mui/material';
import BackButton from '../../../Components/BasicComponents/BackButton';
import TitleComponent from '../../../Components/BasicComponents/TitleComponent';
import {
  assignBusinessApplication,
  BusinessApplication,
  computeBusinessApplicationIssuesSummary,
  isUserAssignedToBusinessApplication,
  isUserSubscribedToBusinessApplication,
  subscribeToBusinessApplication,
  unassignBusinessApplication,
  unsubscribeToBusinessApplication,
} from '../../../Services/BusinessApplicationService';
import BusinessApplicationGeneralDetails from './BusinessApplicationGeneralDetails';
import BusinessApplicationBusinessProfile from './BusinessApplicationBusinessProfile';
import BusinessApplicationLocationsDetails from './BusinessApplicationLocationsDetails';
import BusinessApplicationSchemesDetails from './BusinessApplicationSchemesDetails';
import BusinessApplicationBillingDetails from './BusinessApplicationBillingDetails';
import BusinessApplicationNotes from './BusinessApplicationNotes';
import BusinessApplicationTagsDetails from './BusinessApplicationTagsDetails';
import { StyledButton } from './shared';
import ErrorDialog from '../../../Components/BasicComponents/ErrorDialog';
import BusinessApplicationAuditLogs from './BusinessApplicationAuditLogs';
import useGetBusinessApplicationSettings from '../hooks/useGetBusinessApplicationSettings';

function BusinessApplicationDetails({
  businessApplication,
  onBackButtonHandler,
  onSetBusinessApplicationToEditHandler,
}: Readonly<{
  businessApplication: BusinessApplication;
  onBackButtonHandler: () => void;
  onSetBusinessApplicationToEditHandler: (businessApplication: BusinessApplication) => void
}>) {
  const {
    stripeProducts, supportedCountries, businessCategories, isLoading, error,
  } = useGetBusinessApplicationSettings();
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);
  const [errorSubscription, setErrorSubscription] = useState(false);
  const [isAssignLoading, setIsAssignLoading] = useState(false);
  const [errorAssign, setErrorAssign] = useState(false);
  const [isUserSubscribed, setIsUserSubscribed] = useState(false);
  const [isUserAssigned, setIsUserAssigned] = useState(false);
  const subscribeButtonText = isUserSubscribed ? 'Unsubscribe' : 'Subscribe';
  const assignButtonText = isUserAssigned ? 'Unassign' : 'Assign to you';
  const businessApplicationIssuesSummary = computeBusinessApplicationIssuesSummary(businessApplication);

  useEffect(() => {
    setIsUserSubscribed(isUserSubscribedToBusinessApplication(businessApplication));
    setIsUserAssigned(isUserAssignedToBusinessApplication(businessApplication));
  }, [businessApplication.updatedAt]);

  const subscribeHandler = async () => {
    try {
      setIsSubscriptionLoading(true);

      const result = await subscribeToBusinessApplication(
        businessApplication.businessId,
      );

      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setErrorSubscription(true);
    } finally {
      setIsSubscriptionLoading(false);
    }
  };

  const unsubscribeHandler = async () => {
    try {
      setIsSubscriptionLoading(true);

      const result = await unsubscribeToBusinessApplication(
        businessApplication.businessId,
      );

      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setErrorSubscription(true);
    } finally {
      setIsSubscriptionLoading(false);
    }
  };

  const assignHandler = async () => {
    try {
      setIsAssignLoading(true);
      const result = await assignBusinessApplication(businessApplication.businessId);

      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setErrorAssign(true);
    } finally {
      setIsAssignLoading(false);
    }
  };

  const unassignHandler = async () => {
    try {
      setIsAssignLoading(true);
      const result = await unassignBusinessApplication(businessApplication.businessId);

      onSetBusinessApplicationToEditHandler(result.data);
    } catch (e) {
      console.log(e);
      setErrorAssign(true);
    } finally {
      setIsAssignLoading(false);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <BackButton onClick={() => onBackButtonHandler()} />
        <StyledButton
          height="45px"
          width="200px"
          onClick={() => (isUserSubscribed ? unsubscribeHandler() : subscribeHandler())}
          withChildComponent
        >
          {isSubscriptionLoading ? <CircularProgress size={20} /> : subscribeButtonText}
        </StyledButton>
      </div>
      <Grid container marginTop="25px">
        <Grid item xs={12} sm={6}>
          <TitleComponent
            text={`Business Applications Details - ${businessApplication.businessId}`}
          />
        </Grid>
        <Grid item xs={12} sm={6} justifyContent="flex-end" display="flex" alignItems="center" gap="25px">
          <TitleComponent
            text={
              businessApplication.salesPerson
                ? `Assigned to - ${businessApplication.salesPerson.split('@')[0]}`
                : 'Not assigned'
            }
          />
          <StyledButton
            height="45px"
            width="200px"
            onClick={() => (isUserAssigned ? unassignHandler() : assignHandler())}
            withChildComponent
          >
            {isAssignLoading ? <CircularProgress size={20} /> : assignButtonText}
          </StyledButton>
        </Grid>
      </Grid>

      <Grid container gap={3}>
        <BusinessApplicationNotes
          businessApplication={businessApplication}
          onSetBusinessApplicationToEditHandler={onSetBusinessApplicationToEditHandler}
        />
        <BusinessApplicationGeneralDetails
          businessApplication={businessApplication}
          businessApplicationIssuesSummary={businessApplicationIssuesSummary}
          isDataLoading={isLoading}
          errorOnDataLoading={error}
          supportedCountries={supportedCountries}
          businessCategories={businessCategories}
          onSetBusinessApplicationToEditHandler={onSetBusinessApplicationToEditHandler}
        />
        <BusinessApplicationBusinessProfile
          businessApplication={businessApplication}
          onSetBusinessApplicationToEditHandler={onSetBusinessApplicationToEditHandler}
        />
        <BusinessApplicationLocationsDetails
          businessApplication={businessApplication}
          isDataLoading={isLoading}
          errorOnDataLoading={error}
          supportedCountries={supportedCountries}
          businessCategories={businessCategories}
          onSetBusinessApplicationToEditHandler={onSetBusinessApplicationToEditHandler}
        />
        <BusinessApplicationSchemesDetails
          businessApplication={businessApplication}
          onSetBusinessApplicationToEditHandler={onSetBusinessApplicationToEditHandler}
        />
        <BusinessApplicationTagsDetails
          businessApplication={businessApplication}
          onSetBusinessApplicationToEditHandler={onSetBusinessApplicationToEditHandler}
        />
        <BusinessApplicationBillingDetails
          businessApplication={businessApplication}
          isDataLoading={isLoading}
          errorOnDataLoading={error}
          stripeProducts={stripeProducts}
          supportedCountries={supportedCountries}
          onSetBusinessApplicationToEditHandler={onSetBusinessApplicationToEditHandler}
        />
        <BusinessApplicationAuditLogs
          businessApplication={businessApplication}
          onSetBusinessApplicationToEditHandler={onSetBusinessApplicationToEditHandler}
        />
      </Grid>
      <ErrorDialog
        open={errorSubscription}
        dialogTitle="Something went wrong"
        dialogText="An error occurred while subscribing/unsubscribing, please try again."
        handleClose={() => setErrorSubscription(false)}
      />
      <ErrorDialog
        open={errorAssign}
        dialogTitle="Something went wrong"
        dialogText="An error occurred while assigning the business application to you, please try again."
        handleClose={() => setErrorAssign(false)}
      />
    </>
  );
}

export default BusinessApplicationDetails;
